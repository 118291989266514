var _s = $RefreshSig$();
import { useState, useCallback } from "react";
import { getNewTokens } from "./refreshtoken";
function useLocalStorage(key, initialValue) {
    _s();
    const [storedValue, setStoredValue] = useState(()=>{
        if ("object" === "undefined") {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });
    const [tokenCache, setTokenCache] = useState({
        token: null,
        expiryTime: 0
    });
    const setValue = useCallback((value)=>{
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if ("object" !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            console.error(error);
        }
    }, [
        key,
        storedValue
    ]);
    const removeValue = useCallback(()=>{
        try {
            setStoredValue(initialValue);
            if ("object" !== "undefined") {
                window.localStorage.removeItem(key);
            }
        } catch (error) {
            console.error(error);
        }
    }, [
        initialValue,
        key
    ]);
    const getToken = useCallback(async ()=>{
        const currentTime = Date.now();
        if (tokenCache.token && currentTime < tokenCache.expiryTime) {
            console.log("Using cached token");
            return tokenCache.token;
        }
        console.log("getToken called, storedValue:", storedValue);
        if (!storedValue.idToken || !storedValue.expiry) {
            console.log("No idToken or expiry in storedValue");
            return null;
        }
        const expiryTime = new Date(storedValue.expiry).getTime();
        if (currentTime < expiryTime) {
            console.log("Token is still valid");
            setTokenCache({
                token: storedValue.idToken,
                expiryTime
            });
            return storedValue.idToken;
        }
        if (storedValue.refreshToken) {
            try {
                console.log("Attempting to refresh token");
                const newTokens = await getNewTokens(storedValue.refreshToken);
                console.log("Token refreshed successfully");
                setValue({
                    idToken: newTokens.idToken,
                    refreshToken: newTokens.refreshToken,
                    expiry: newTokens.expiry
                });
                setTokenCache({
                    token: newTokens.idToken,
                    expiryTime: new Date(newTokens.expiry).getTime()
                });
                return newTokens.idToken;
            } catch (error) {
                console.error("Error refreshing token:", error);
                removeValue();
                return null;
            }
        }
        console.log("No refresh token available");
        return null;
    }, [
        storedValue,
        setValue,
        removeValue
    ]);
    return [
        storedValue,
        setValue,
        removeValue,
        getToken
    ];
}
_s(useLocalStorage, "gY2aNKeN/Yj9GZuDd3koLkpww80=");
export default useLocalStorage;


;
    // Wrapped in an IIFE to avoid polluting the global scope
    ;
    (function () {
        var _a, _b;
        // Legacy CSS implementations will `eval` browser code in a Node.js context
        // to extract CSS. For backwards compatibility, we need to check we're in a
        // browser context before continuing.
        if (typeof self !== 'undefined' &&
            // AMP / No-JS mode does not inject these helpers:
            '$RefreshHelpers$' in self) {
            // @ts-ignore __webpack_module__ is global
            var currentExports = __webpack_module__.exports;
            // @ts-ignore __webpack_module__ is global
            var prevSignature = (_b = (_a = __webpack_module__.hot.data) === null || _a === void 0 ? void 0 : _a.prevSignature) !== null && _b !== void 0 ? _b : null;
            // This cannot happen in MainTemplate because the exports mismatch between
            // templating and execution.
            self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
            // A module can be accepted automatically based on its exports, e.g. when
            // it is a Refresh Boundary.
            if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
                // Save the previous exports signature on update so we can compare the boundary
                // signatures. We avoid saving exports themselves since it causes memory leaks (https://github.com/vercel/next.js/pull/53797)
                __webpack_module__.hot.dispose(function (data) {
                    data.prevSignature =
                        self.$RefreshHelpers$.getRefreshBoundarySignature(currentExports);
                });
                // Unconditionally accept an update to this module, we'll check if it's
                // still a Refresh Boundary later.
                // @ts-ignore importMeta is replaced in the loader
                import.meta.webpackHot.accept();
                // This field is set when the previous version of this module was a
                // Refresh Boundary, letting us know we need to check for invalidation or
                // enqueue an update.
                if (prevSignature !== null) {
                    // A boundary can become ineligible if its exports are incompatible
                    // with the previous exports.
                    //
                    // For example, if you add/remove/change exports, we'll want to
                    // re-execute the importing modules, and force those components to
                    // re-render. Similarly, if you convert a class component to a
                    // function, we want to invalidate the boundary.
                    if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevSignature, self.$RefreshHelpers$.getRefreshBoundarySignature(currentExports))) {
                        __webpack_module__.hot.invalidate();
                    }
                    else {
                        self.$RefreshHelpers$.scheduleUpdate();
                    }
                }
            }
            else {
                // Since we just executed the code for the module, it's possible that the
                // new exports made it ineligible for being a boundary.
                // We only care about the case when we were _previously_ a boundary,
                // because we already accepted this update (accidental side effect).
                var isNoLongerABoundary = prevSignature !== null;
                if (isNoLongerABoundary) {
                    __webpack_module__.hot.invalidate();
                }
            }
        }
    })();
